.box {
    margin: 0px;
    top: auto;
    right: 6em;
    bottom: 4.5em;
    left: auto;
    position: fixed;
}

.classes.fab {
    color: #224AB9;
}
