.w {
  height: 100%;
  border-top: 3px solid #224ab9;
}

.w-wrapper {
  padding: 0 5vw;
}

.w-p {
  text-align: justify;
}

.w-pn {
  font-size: .82em;
  text-align: justify;
}

.blueText {
  color: #224ab9;
}

.redText {
  color: #ef3a40;
}

.bold {
  font-weight: bold;
}

/* Small lanscape phones */
@media (min-width: 575.98px) { 
  .w {
    font-size: 110%;
  }
  
  .w-wrapper{
    padding: 0 7vw;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (min-width: 767.98px) { 
    .w {
      font-size: 130%
    }
 }

/* Medium devices (tablets, less than 992px) */
@media (min-width: 991.98px) { 
    .w {
      font-size: 150%;
    }

    .w-wrapper {
      padding: 0 15vw;
    }
 }

/* Large devices (desktops, less than 1200px) */
@media (min-width: 1199.98px) { 
  .w {
    font-size: 175%;
  }

  .w-wrapper {
    padding: 0 20vw;
  }
 }