.a {
    height: 100%;
    border-top: 3px solid #224AB9;
}

.a-wrapper {
    padding: 0 5vw;
}

.a-p {
    font-size: .85em;
    text-align: justify;
}

.a-sig {
    text-align: left;
    font-weight: bold;
}

.a-email {
    text-decoration: none;
}

.redText {
    color: #EF3A40;
}

.blueText {
    color: #224AB9;
}

/* Small lanscape phones */
@media (min-width: 575.98px) { 
    .a {
        font-size: 110%;
    }
    
    .a-wrapper {
        padding: 0 7vw;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (min-width: 767.98px) { 
    .a{
        font-size: 130%;
    }
 }

/* Medium devices (tablets, less than 992px) */
@media (min-width: 991.98px) { 
    .a {
        font-size: 150%;
    }

    .a-wrapper {
        padding: 0 15vw;
    }
 }

/* Large devices (desktops, less than 1200px) */
@media (min-width: 1199.98px) { 
    .a {
        font-size: 175%;
    }

    .a-wrapper {
        padding: 0 20vw;
    }
 }