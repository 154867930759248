.r {
  height: 100%;
  border-top: 3px solid #224ab9;
}

.r-wrapper {
    padding: 0 5vw;
}

.r-p {
  font-size: 0.9em;
  text-align: justify;
}

.r-link {
  text-decoration: none;
}

.redText {
    color: #ef3a40;
  }

.blueText {
    color: #224ab9;
}

.bold {
  font-weight: bold;
}

/* Small lanscape phones */
@media (min-width: 575.98px) { 
  .r {
    font-size: 110%;
  }
  
  .r-wrapper {
    padding: 0 7vw;
  }

}

/* Small devices (landscape phones, less than 768px) */
@media (min-width: 767.98px) { 
    .r {
      font-size: 130%;
    }
 }

/* Medium devices (tablets, less than 992px) */
@media (min-width: 991.98px) { 
    .r {
      font-size: 150%;
    }

    .r-wrapper {
      padding: 0 15vw;
    }
 }

/* Large devices (desktops, less than 1200px) */
@media (min-width: 1199.98px) { 
    .r {
        font-size: 175%;
    }

    .r-wrapper {
      padding: 0 20vw;
  }
 }