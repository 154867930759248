.h {
    height: 100%;
    border-top: 3px solid #224AB9;
    /* display: flex; */
}

.h-wrapper {
    max-height: 500px;
}

.h-left {
    display: none;
}

.h-right {
    padding: 2vh 3vw;
}

.h-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Small lanscape phones */
@media (min-width: 575.98px) { 

    .h-wrapper {
        display: flex;
    }

    .h-left{
        padding: 3vh 3vw;
        display: block;
        width: 60%;
    }

    .h-right {
        width: 100%;
    }

    .left {
        height: 100%;
    }
 }

/* Small devices (landscape phones, less than 768px) */
@media (min-width: 767.98px) { 
    /* Nothing on header*/
 }

/* Medium devices (tablets, less than 992px) */
@media (min-width: 991.98px) { 
    /* Nothing on header*/
 }

/* Large devices (desktops, less than 1200px) */
@media (min-width: 1199.98px) { 

 }